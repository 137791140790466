exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-accessibility-js": () => import("./../../../src/pages/accessibility.js" /* webpackChunkName: "component---src-pages-accessibility-js" */),
  "component---src-pages-activate-js": () => import("./../../../src/pages/activate.js" /* webpackChunkName: "component---src-pages-activate-js" */),
  "component---src-pages-activate-online-js": () => import("./../../../src/pages/activate-online.js" /* webpackChunkName: "component---src-pages-activate-online-js" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-coverage-tsx": () => import("./../../../src/pages/coverage.tsx" /* webpackChunkName: "component---src-pages-coverage-tsx" */),
  "component---src-pages-crtc-js": () => import("./../../../src/pages/crtc.js" /* webpackChunkName: "component---src-pages-crtc-js" */),
  "component---src-pages-help-contentful-faq-category-page-slug-tsx": () => import("./../../../src/pages/help/{ContentfulFaqCategory.pageSlug}.tsx" /* webpackChunkName: "component---src-pages-help-contentful-faq-category-page-slug-tsx" */),
  "component---src-pages-help-index-tsx": () => import("./../../../src/pages/help/index.tsx" /* webpackChunkName: "component---src-pages-help-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-js": () => import("./../../../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-locations-tsx": () => import("./../../../src/pages/locations.tsx" /* webpackChunkName: "component---src-pages-locations-tsx" */),
  "component---src-pages-long-distance-js": () => import("./../../../src/pages/long-distance.js" /* webpackChunkName: "component---src-pages-long-distance-js" */),
  "component---src-pages-media-centre-js": () => import("./../../../src/pages/media-centre.js" /* webpackChunkName: "component---src-pages-media-centre-js" */),
  "component---src-pages-migrate-optimum-form-js": () => import("./../../../src/pages/migrate-optimum-form.js" /* webpackChunkName: "component---src-pages-migrate-optimum-form-js" */),
  "component---src-pages-offer-js": () => import("./../../../src/pages/offer.js" /* webpackChunkName: "component---src-pages-offer-js" */),
  "component---src-pages-pco-optimum-form-js": () => import("./../../../src/pages/pco-optimum-form.js" /* webpackChunkName: "component---src-pages-pco-optimum-form-js" */),
  "component---src-pages-phones-compare-js": () => import("./../../../src/pages/phones/compare.js" /* webpackChunkName: "component---src-pages-phones-compare-js" */),
  "component---src-pages-phones-contentful-phone-item-page-slug-js": () => import("./../../../src/pages/phones/{ContentfulPhoneItem.pageSlug}.js" /* webpackChunkName: "component---src-pages-phones-contentful-phone-item-page-slug-js" */),
  "component---src-pages-phones-index-js": () => import("./../../../src/pages/phones/index.js" /* webpackChunkName: "component---src-pages-phones-index-js" */),
  "component---src-pages-plans-tsx": () => import("./../../../src/pages/plans.tsx" /* webpackChunkName: "component---src-pages-plans-tsx" */),
  "component---src-pages-postpaid-faq-js": () => import("./../../../src/pages/postpaid-faq.js" /* webpackChunkName: "component---src-pages-postpaid-faq-js" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-promos-js": () => import("./../../../src/pages/promos.js" /* webpackChunkName: "component---src-pages-promos-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-pages-top-up-tsx": () => import("./../../../src/pages/top-up.tsx" /* webpackChunkName: "component---src-pages-top-up-tsx" */),
  "component---src-pages-website-terms-conditions-js": () => import("./../../../src/pages/website-terms-conditions.js" /* webpackChunkName: "component---src-pages-website-terms-conditions-js" */),
  "component---src-templates-campaign-index-js": () => import("./../../../src/templates/campaign/index.js" /* webpackChunkName: "component---src-templates-campaign-index-js" */),
  "component---src-templates-faq-category-answers-index-js": () => import("./../../../src/templates/FaqCategoryAnswers/index.js" /* webpackChunkName: "component---src-templates-faq-category-answers-index-js" */),
  "component---src-templates-redirect-index-js": () => import("./../../../src/templates/redirect/index.js" /* webpackChunkName: "component---src-templates-redirect-index-js" */)
}

