import React from 'react';
import { Script } from 'gatsby';

const CookieScript = () => (
  <>
    <Script
      src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
      data-document-language="true"
      type="text/javascript"
      charSet="UTF-8"
      data-domain-script={process.env.ONETRUST_COOKIE_DOMAIN_SCRIPT}
      className={process.env.ONETRUST_COOKIE_DOMAIN_SCRIPT}
    />
    <Script
      src="/OneTrustCookieScript.js"
      type="text/javascript"
      className="onetrust"
    />
  </>
);

export default CookieScript;
